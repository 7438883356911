import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeadlessCTA from "../components/headlessCTA"
import SplashImage from "../images/Mock.png"
import InvestorLogos from "../images/investor-logos.png"

import CTA from "../components/cta"

const WhyTacticPage = () => {
  const cardBg =
    "bg-white/10 backdrop-blur-xl rounded-lg p-8 flex-1 hover:bg-white hover:text-black hover:shadow-2xl transition duration-500 cursor-pointer group"

  return (
    <Layout>
      <SEO
        title="Why Tactic?"
        description="Tactic enables increased sales efficiency, data automation, and faster iteration in today's uncertain markets."
      />

      <div className="container mx-auto py-8 md:py-16">
        <div
          className="flex flex-col gap-8 text-center items-center mx-auto mb-24"
          id="hero-container"
        >
          <span className="text-white/75">Why Tactic?</span>
          <h1
            className="font-bold text-white text-5xl md:text-7xl lg:text-8xl max-w-7xl"
            style={{
              mixBlendMode: "overlay",
              fontFeatureSettings: "'ss02' on, 'ss03' on, 'ss01' on",
            }}
          >
            Faster data. Faster iteration. Faster revenue.
          </h1>
          <h2
            className="text-white text-xl lg:text-2xl max-w-5xl"
            style={{ fontFeatureSettings: "'salt' on" }}
          >
            Tactic is a faster and more efficient way to iterate on your
            targeting, timing, and messaging using on internet and people data.
            The faster you optimise your go-to-market data, the faster you hit
            your revenue goals.
          </h2>
          <HeadlessCTA />
        </div>
        <img src={SplashImage} />
      </div>
      <div className="max-w-3xl mx-auto py-8 md:py-16">
        <div className="text-blue text-sm uppercase tracking-widest">
          Why now?
        </div>
        <h2 className="font-semibold text-4xl my-12 text-white">
          Efficiency is the best answer to uncertainty.
        </h2>
        <div className="prose prose-xl text-dark-grey">
          <p>
            In today's fast changing markets, revenue efficiency is most
            important. Having the right data is now an urgent issue: data helps
            reps and marketing dollars go after the right prospects in high fit
            accounts. It works for both high performing and strugglging reps
            &mdash; and not spending resources on the wrong prospects and
            audiences is the definition of efficiency.
          </p>
        </div>
        <div className="text-blue text-sm uppercase tracking-widest mt-12">
          Why anything?
        </div>
        <h2 className="font-semibold text-4xl my-12 text-white">
          The right insights enable you to optimise for efficient decisions.
        </h2>
        <div className="prose prose-xl text-dark-grey">
          <p>
            Free flowing capital created a crowded marketplace. In response,
            revenue leaders invested heavily in data, so that they can
            understand their buyers and stand out in the market. Cutting edge
            businesses usually mandate sales and marketing teams to do manual
            research and data gathering, have a large stack of data vendors, and
            finally teams of data analysts and interns. Insight is powerful, but
            it is expensive and a whole new thing to scale.
          </p>
        </div>
        <div className="text-blue text-sm uppercase tracking-widest mt-12">
          Why Tactic?
        </div>
        <h2 className="font-semibold text-4xl my-12 text-white">
          Tactic crunches the entire internet to give you smarter targeting,
          timing, and messaging.
        </h2>
        <div className="prose prose-xl text-dark-grey">
          <ul>
            <li>
              <strong>Remove manual research and data entry</strong>{" "}
              Customisable and continuous intelligence is our USP: Tactic
              indexes the entire internet continuously. Specify the data you
              need for each company/person and Tactic will find and collate at a
              rate of 10,000+ records/day.
            </li>
            <li>
              <strong>Consolidate data vendors</strong> Tactic provides account
              intelligence from firmographics, technology usage, growth,
              funding, M&amp;A, hiring data to niche data points e.g. “payment
              methods accepted”, ”net zero targets”, and “cost cutting
              initiatives”. Prospect Intelligence such as new leadership,
              podcast appearances, events, interviews, C-level quotes and more.
              All in one platform!
            </li>
            <li>
              <strong>Refocus your data analysts and interns</strong> Save time
              and resources on alignment meetings to get research, review
              meetings to fix data, and headaches with ad hoc spreadsheets. Stop
              spinning wheels with data teams producing data that is never used
              by business users.
            </li>
          </ul>
        </div>
      </div>
      <section className="max-w-6xl mx-auto text-white grid grid-cols-1 lg:grid-cols-2 gap-1 my-12">
        <div className={cardBg}>
          <h3 className="text-4xl font-light text-green-500 mb-12">
            Global coverage and multi-lingual
          </h3>
          <p className="text-lg text-white/50 group-hover:text-dark-grey">
            Unlike directories like Zoominfo, we cover any region by leveraging
            company websites and local news to extract the data you need.
          </p>
        </div>
        <div className={cardBg}>
          <h3 className="text-4xl font-light text-green-500 mb-12">
            Scalable and flexible
          </h3>
          <p className="text-lg text-white/50 group-hover:text-dark-grey">
            Create in-depth research reports with unlimited detail, enrich CRM
            records with any number of attributes (at a speed of over 10,000
            companies a day).
          </p>
        </div>
        <div className={cardBg}>
          <h3 className="text-4xl font-light text-green-500 mb-12">
            Salesforce Integration
          </h3>
          <p className="text-lg text-white/50 group-hover:text-dark-grey">
            Deep integration with Salesforce including imports, exports, custom
            object support, automatic syncing, and flexible field mappings.
          </p>
        </div>
        <div className={cardBg}>
          <h3 className="text-4xl font-light text-green-500 mb-12">
            Simple &amp; user friendly
          </h3>
          <p className="text-lg text-white/50 group-hover:text-dark-grey">
            Tactic's deep UX research has resulted in an interface that ensures
            wide and growing adoption across sales, marketing, partnerships, and
            revops.
          </p>
        </div>
      </section>

      <section className="text-white py-24 gradient-background-animated">
        <h2 className="text-white text-5xl font-bold text-center max-w-4xl mx-auto">
          Loved by hypergrowth startups and Fortune 500 alike
        </h2>

        <div className="container mx-auto text-white grid grid-cols-1 lg:grid-cols-3 gap-1 mt-24">
          <div className="rounded-xl bg-black/25 p-8">
            <div className="text-sm uppercase tracking-widest">For sales</div>
            <blockquote className="text-lilac text-2xl my-8">
              “Tactic helps us save 10 hours/rep/week.”
            </blockquote>
            <p className="text-lg mb-4">
              <strong className="text-blue">Increase efficiency</strong> &mdash;
              save time finding data; spend more time selling
            </p>
            <p className="text-lg mb-4">
              <strong className="text-blue">Decrease ramp time</strong> &mdash;
              train reps on how to sell, not how to research
            </p>
            <p className="text-lg mb-4">
              <strong className="text-blue">Increase pipeline</strong> &mdash;
              the right information in CRM = better prospecting
            </p>
          </div>
          <div className="rounded-xl bg-black/25 p-8">
            <div className="text-sm uppercase tracking-widest">
              For marketing
            </div>
            <blockquote className="text-lilac text-2xl my-8">
              “With Tactic, I can iterate on audiences rapidly to try different
              ideas.”
            </blockquote>
            <p className="text-lg mb-4">
              <strong className="text-blue">Increase marketing ROI</strong>{" "}
              &mdash; smarter targeting increases conversion rates
            </p>
            <p className="text-lg mb-4">
              <strong className="text-blue">
                Decrease time to launch campaigns
              </strong>{" "}
              &mdash; save time building audiences
            </p>
            <p className="text-lg mb-4">
              <strong className="text-blue">Sales/marketing alignment</strong>{" "}
              &mdash; all GTM teams acting on the same data
            </p>
          </div>
          <div className="rounded-xl bg-black/25 p-8">
            <div className="text-sm uppercase tracking-widest">
              For SALES/REVENUE OPS
            </div>
            <blockquote className="text-lilac text-2xl my-8">
              “Tactic enriched 10 attributes on 30,000 accounts for us.”
            </blockquote>
            <p className="text-lg mb-4">
              <strong className="text-blue">Enrich with the right data</strong>{" "}
              &mdash; unlimited selection of data attributes
            </p>
            <p className="text-lg mb-4">
              <strong className="text-blue">Improve data quality</strong>{" "}
              &mdash; continuously extracted intelligence in your CRM
            </p>
            <p className="text-lg mb-4">
              <strong className="text-blue">Increase trust in data</strong>{" "}
              &mdash; visualise where the data came from using UI
            </p>
          </div>
        </div>
      </section>

      <div className="max-w-3xl mx-auto py-8 md:py-16">
        <div className="prose prose-xl text-dark-grey">
          <h2 id="the-right-information-integrated-to-your-stack">
            Integrations
          </h2>
          <ul>
            <li>
              <strong>Salesforce integration</strong> &mdash; import, exports,
              and sync
            </li>
            <li>
              <strong>Excel/CSV integration</strong> &mdash; import and exports
            </li>
            <li>
              <strong>Web application</strong>
            </li>
            <li>
              <strong>LinkedIn integration</strong>
            </li>
          </ul>
          <h2 id="security-and-compliance">Security and compliance</h2>
          <ul>
            <li>
              <strong>SOC 2</strong> &mdash; coming in Q4
            </li>
            <li>
              <strong>Security</strong> &mdash; dedicated security team, IAM and
              monitoring
            </li>
            <li>
              <strong>Compliance</strong> &mdash; tools to help with GDPR
            </li>
            <li>
              <strong>Privacy</strong> &mdash; privacy focus and data compliance
            </li>
          </ul>
          <h2 id="white-glove-customer-success">
            White glove customer success
          </h2>
          <ul>
            <li>
              <strong>Dedicated account manager</strong>
            </li>
            <li>
              <strong>
                Implementation, success tracking, and onboarding packages
              </strong>
            </li>
            <li>
              <strong>Exec reporting</strong>
            </li>
            <li>
              <strong>Data consulting services</strong>
            </li>
          </ul>
          <h2 id="backed-by-world-class-vcs">Backed by world class VCs</h2>
        </div>
        <img src={InvestorLogos} className="mx-auto my-12" />
      </div>

      <CTA />
    </Layout>
  )
}

export default WhyTacticPage
